@font-face {
  font-family: "MS Dos";
  src: url("./font/DOS-VGA-437.ttf");
}

@font-face {
  font-family: "PX Sans";
  src: url("./font/px_sans_nouveaux.ttf");
}

:root {
  --desktop-colour: #008484;
  --windows-grey: #c0c0c0;
  --menubar-blue: #010479;
  --border-light: #ffffff;
  --border-dark: #6d6d6d;
  --px-sans: "PX Sans";
  user-select: none;
  --black-white-border: 2px solid white 2px solid white 2px solid black 2px
    solid black;
}

html,
body {
  font-family: "MS Dos";
  width: 100vw;
  height: 100vh;
  font-size: 16px;
}

html {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8 #e1e1e1;
}

/* Works on Chrome/Edge/Safari */
::-webkit-scrollbar {
  width: 15px;
  cursor: url(./image/cursors/cursor.svg), auto;
  background: url(./image/windows/scrollbar-background.svg);
}

::-webkit-scrollbar-track {
  background: url(./image/windows/scrollbar-background.svg);
  cursor: url(./image/cursors/cursor.svg), auto;
}
::-webkit-scrollbar-thumb {
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
  background-color: var(--windows-grey);
  cursor: url(./image/cursors/cursor.svg), auto;
}

form,
input,
button {
  font-family: var(--px-sans);
  font-size: 100%;
}

a:visited {
  color: #0000ee;
}

#boot-wrapper {
  box-sizing: border-box;
  padding: 2%;
  font-family: "MS Dos";
  font-size: 32px;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: black;
  color: white;
}

.bootText {
  height: 50px;
}

#shut-down-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  image-rendering: crisp-edges;
  z-index: 98;
}

#screen-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

#desktop-wrapper {
  width: 100vw;
  display: flex;
  background-color: var(--desktop-colour);
  flex-grow: 1;
}

#desktop {
  height: 100%;
  width: 100%;
}

#taskbar-wrapper {
  display: flex;
  width: 100%;
  height: 40px;
}

#taskbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: var(--windows-grey);
  border-top: 1px solid var(--border-light);
  outline: 1px solid var(--windows-grey);
}

#start-button-wrapper {
  display: flex;
  height: 100%;
  width: 100px;
  align-items: center;
}

#start-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  height: 80%;
  width: 90%;
  font-family: "MS Dos";
  letter-spacing: -1px;
  color: black;
  background-color: var(--windows-grey);
  font-size: 16px;
}

#start-button-logo-container {
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-button-focused {
  background-color: #babfc3;
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  border-top: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-dark);
}

.start-button-unfocused {
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
}

#start-button-icon-wrapper {
  height: 100%;
  width: 60%;
}

#start-button-icon {
  width: 100%;
  height: 100%;
  background-image: url(./image/system-icons/windows-logo.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#start-menu-label-wrapper {
  height: 100%;
  width: 60%;
}

#start-button-label {
  margin-left: 2px;
  margin-right: 2px;
}

#start-menu-wrapper {
  z-index: 98;
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 5px;
  height: 400px;
  width: 260px;
  border-right: 2px solid var(--border-dark);
  border-bottom: 2px solid var(--border-dark);
  border-top: 2px solid var(--border-light);
}

#start-menu {
  font-family: "PX Sans";
  font-size: 80%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  width: 100%;
  height: 100%;
}

#start-menu-panel-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-light);
}

#start-menu-logo-wrapper {
  display: flex;
  flex-direction: column-reverse;
  width: 15%;
  height: 100%;
  background-color: #808080;
  align-items: center;
}

#start-menu-logo {
  margin-bottom: 20%;
  width: 80%;
  height: 100%;
  background-image: url("./image/desktop/start-menu-logo.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.start-menu-item-wrapper {
  width: 100%;
  height: 60px;
  color: black;
  display: flex;
}

.start-menu-item-wrapper:hover {
  color: white;
  background-color: var(--menubar-blue);
}

.start-menu-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 98%;
  width: 98%;
  margin: 1% 0 0 1%;
}

.start-menu-item-image {
  height: 80%;
  width: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 70px;
  margin: 20px;
  align-items: center;
  user-select: none;
}

.icon-wrapper:hover {
  filter: brightness(90%);
}

.icon-wrapper:active {
  filter: brightness(120%);
}

.icon {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

.icon-label {
  color: white;
}

.icon-focused {
  outline: darkgray dashed thin;
}

.icon-not-focused {
  outline: none;
}

#system-tray-wrapper {
  height: 100%;
  width: 70px;
  display: flex;
  margin-left: auto;
  align-items: center;
}

#system-tray {
  background-color: var(--windows-grey);
  border-top: 1px solid var(--border-dark);
  border-left: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  height: 80%;
  width: 100%;
  margin-right: 5%;
}

#clock-wrapper {
  font-family: var(--px-sans);
  font-size: 70%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.window-wrapper {
  top: 0;
  left: 0;
  position: absolute;
  flex-direction: column;
  padding: 4px;
  background-color: var(--windows-grey);
  border-right: 3px solid #112122;
  border-bottom: 3px solid #112122;
  border-left: 3px solid #eef0ea;
  border-top: 3px solid #eef0ea;
}

.window-open {
  display: flex;
}

.window-minimised {
  display: none;
}

.window {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.window-title-bar-container {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  height: 30px;
  width: 100%;
}

.title-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-bar-focused {
  color: white;
  background-color: var(--menubar-blue);
}

.title-bar-unfocused {
  color: #bfb8bf;
  background-color: #7f787f;
}

.window-title-bar-draggable {
  display: flex;
  flex: 1 1 auto;
}

.title-bar-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.title-bar-icon {
  height: 80%;
  width: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-bar-label-wrapper {
  margin-left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 140%;
}

.window-controls-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 30px;
  align-items: center;
}

.window-control-wrapper {
  display: flex;
  height: 24px;
  width: 24px;
  margin: 0 4px 0 4px;
}

.window-control {
  width: 100%;
  background-color: var(--windows-grey);
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.window-control:active {
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  border-top: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-dark);
}

.window-min {
  background-image: url(./image/windows/minimise.svg);
}

.window-max {
  background-image: url(./image/windows/maximise.svg);
}

.window-close {
  background-image: url(./image/windows/close.svg);
}

.window-toolbar-container {
  display: flex;
  flex: 0;
  width: 100%;
  height: 30px;
  font-family: "PX Sans";
  font-size: 70%;
  user-select: none;
}

.window-toolbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
}

.window-toolbar-item {
  margin: 0 10px 0 10px;
}

.window-file-container-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
}

.window-file-container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: #fff;
  border-left: 2px solid #434547;
  border-top: 2px solid #434547;
  border-right: 1px solid #e1e3e5;
  border-bottom: 1px solid #e1e3e5;
}

.window-details-panes-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
}

.window-detail-pane-wrapper {
  display: flex;
  width: 50%;
  height: 80%;
}

.window-detail-pane {
  height: 98%;
  width: 98%;
  border-left: 2px solid #8c8c8c;
  border-top: 2px solid #8c8c8c;
  border-right: 2px solid #e1e3e5;
  border-bottom: 2px solid #e1e3e5;
}

.window-detail-pane-left-wrapper {
  justify-content: flex-start;
}

.window-detail-pane-right-wrapper {
  justify-content: flex-end;
  position: relative;
}

/* The visual handle */
.window-resize-handle {
  width: 20px;
  height: 20px;
  background-image: url("./image/windows/resize-handle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

/* The draggable element */
.resize-handle {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
  background-color: black;
  opacity: 0%;
}

.resize-handle:hover {
  cursor: url("./image/cursors/resize-cursor.svg"), auto;
}

#taskbar-windows-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 100%;
}

.taskbar-window-wrapper {
  height: 100%;
  width: 200px;
  display: flex;
  align-items: center;
}

.taskbar-window {
  font-size: 16px;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.taskbar-window-open {
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  border-top: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-dark);
  background: url(./image/windows/taskbar-selected-background.svg);
}

.taskbar-window-minimised {
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
}

.taskbar-window-icon-wrapper {
  display: flex;
  height: 100%;
  width: 15%;
  justify-content: center;
  align-items: center;
}

.taskbar-window-icon {
  height: 100%;
  width: 100%;
  margin: 5%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.taskbar-window-label-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.taskbar-window-label {
  margin-left: 2.5%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: "..";
}

#dyk-container {
  background-image: url(./image/windows/pc-background.svg);
  display: flex;
  flex-direction: row;
  font-family: "PX Sans";
  padding: 2.5%;
  height: 90%;
  width: 90%;
  margin: 2.5%;
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
}

#dyk-icon-wrapper {
  height: 100%;
  padding-right: 5%;
  width: 10%;
}

#dyk-icon {
  width: 100%;
  height: 100%;
  background-image: url(./image/windows/tip-of-the-day.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

#dyk-main-wrapper {
  padding-top: 2.5%;
  height: 95%;
  width: 80%;
}

#dyk-text-wrapper {
  height: 20%;
  width: 100%;
}

#dyk-pc-wrapper {
  height: 80%;
  width: 100%;
}

#dyk-pc {
  height: 100%;
  width: 100%;
  background-image: url(./image/windows/did-you-know-pc.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

#dyk-did-you-know-message {
  padding-bottom: 20px;
  -webkit-text-stroke: 1px black;
  letter-spacing: 2px;
}

#sp-container {
  font-family: "PX Sans";
  font-size: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-top: 2px solid var(--border-light);
  border-left: 2px solid var(--border-light);
  border-bottom: 2px solid var(--border-dark);
  border-right: 2px solid var(--border-dark);
  margin-top: 5px;
}

#sp-pc-wrapper {
  width: 25%;
  margin-left: 10%;
  margin-right: 15%;
  margin-top: 10%;
}

#sp-pc {
  height: 100%;
  width: 100%;
  background-image: url(./image/windows/pc-windows-logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

#sp-text-container {
  margin-top: 5%;
  width: 50%;
  height: 100%;
}

.sp-section-container {
  margin-bottom: 20px;
}

.sp-list-item-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

#shut-down-window-container {
  font-family: "PX Sans";
  font-size: 75%;
  height: 100%;
  width: 100%;
  display: flex;
}

#shut-down-icon-wrapper {
  width: 20%;
  margin: 5%;
}

#shut-down-icon {
  height: 100%;
  width: 100%;
  background-image: url(./image/system-icons/shutdown.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

#shut-down-main-container {
  width: 100%;
  margin: 5% 5% 5% 0%;
}

#shut-down-are-you-sure-wrapper {
  height: 20%;
  width: 100%;
}

#shut-down-choices-container {
  height: 60%;
  width: 100%;
}

#shut-down-button-container {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
}

.shut-down-button-wrapper {
  width: 100px;
  height: 30px;
  margin-right: 40px;
}

.shut-down-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 2px solid var(--border-light);
  border-left: 2px solid var(--border-light);
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.shut-down-button:active {
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  border-top: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-dark);
}

#shut-down-window-container {
  display: flex;
  height: 100%;
  width: 100%;
}

#shut-down-logo-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

#shut-down-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./image/desktop/shutdown-logo.svg);
  height: 60%;
  width: 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#shut-down-cloud {
  overflow: hidden;
  width: 1px;
  height: 1px;
  transform: translate(-100%, -100%);
  border-radius: 50%;
  filter: url(#filter);
}

#shut-down-container {
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

#shut-down-safe {
  color: white;
  font-size: 200%;
  text-align: center;
}

input[type="radio"] {
  display: none;
}

form,
label {
  cursor: url(./image/cursors/cursor.svg), auto;
}

.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 50%;
  border-bottom: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
  border-top: 2px solid var(--border-dark);
  border-left: 2px solid var(--border-dark);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: white;
  outline: 1px black;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
}

.ie-container {
  height: calc(100% - 30px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

#ie-toolbar-container {
  height: 60px;
}

.ie-icons-wrapper-container {
  height: 120px;
  display: flex;
  flex: 0;
  flex-direction: row;
  border-bottom: 2px solid white;
  border-top: 2px solid white;
}

.ie-icons-wrapper {
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #828282;
  border-top: 2px solid #828282;
  align-items: center;
  padding-left: 10px;
  padding-right: auto;
}

#ie-icons-wrapper-padder {
  width: 100%;
}

.ie-icon-wrapper {
  height: 35px;
  width: 35px;
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.ie-icon {
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-right: 1px solid #828282;
  border-bottom: 1px solid #828282;
  image-rendering: crisp-edges;
}

.ie-url-container {
  font-family: "PX Sans";
  font-size: 75%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}

.ie-url-bar-wrapper {
  display: flex;
  width: 95%;
  height: 100%;
  align-items: center;
}

.ie-url-bar {
  display: flex;
  flex: 1;
  border-right: 2px solid #c3c3c3;
  border-bottom: 2px solid #c3c3c3;
  border-top: 2px solid black;
  border-left: 2px solid black;
  padding: 0 5px 0 5px;
}

.open-in-browser-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-right: 1px solid #828282;
  border-bottom: 1px solid #828282;
  border-top: 2px solid white;
  border-left: 2px solid white;
  cursor: url(./image/cursors/cursor.svg) 6 0, auto;
}

.open-in-browser-button-wrapper:hover {
  background-color: #a5a5a5;
}

.open-in-browser-button-wrapper:active {
  border-left: 1px solid #828282;
  border-top: 1px solid #828282;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.open-in-browser-button {
  height: 100%;
  border-left: 2px solid #c3c3c3;
  border-top: 2px solid #c3c3c3;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  padding-left: 5px;
  padding-right: 5px;
}

.open-in-browser-button:active {
  border-right: 2px solid #c3c3c3;
  border-bottom: 2px solid #c3c3c3;
  border-top: 2px solid black;
  border-left: 2px solid black;
}

.ie-content-wrapper {
  display: flex;
  overflow-y: scroll;
  background-color: white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-top: 2px solid black;
  border-left: 2px solid black;
  flex: 1;
}

.git-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.git-header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  width: 100%;
  background-color: black;
}

.git-header-element-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.git-header-label-wrapper {
  margin-top: 10px;
}

.git-header-icon-wrapper {
  display: flex;
  height: 100%;
  width: 10%;
  padding-left: 10px;
  padding-right: 10px;
}

.git-header-icon {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  image-rendering: crisp-edges;
}

#gitlab-header-icon {
  background-image: url(./image/desktop-icons/GitLabLogo.svg);
}

#github-header-icon {
  background-image: url(./image/desktop-icons/GitHubLogoInvert.png);
}

.git-header-label-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.git-header-label {
  font-size: 450%;
  color: white;
}

.git-repos-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2.5%;
  padding-bottom: 5%;
}

.repo-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 40%;
  border: 1px solid grey;
  background-color: whitesmoke;
  padding: 10px;
  margin: 10px;
  cursor: url(./image/cursors/cursor.svg) 6 0, auto;
}

.repo-wrapper:hover {
  background-color: rgb(219, 219, 219);
}

.repo-language-wrapper {
  height: 40%;
}

.repo-title-wrapper {
  display: flex;
  flex-direction: row;
  height: 20%;
  font-size: 140%;
}

.repo-description-wrapper {
  display: flex;
  flex: 1;
  margin-top: 10px;
  margin-bottom: 10px;
}

.repo-language-bar {
  display: flex;
  width: 100%;
  height: 1em;
  margin-bottom: 0.5em;
}

.repo-language-keys-container {
  display: flex;
  flex-wrap: wrap;
}

.language-key-container {
  display: flex;
  height: 10%;
  margin-right: 1em;
}

.language-key-colour-wrapper {
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
}

.language-key-colour {
  height: 100%;
  width: 100%;
}

/* ------------ Notepad Window ------------ */

#notepad-component-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

#notepad-text-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2px;
  background-color: white;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-top: 2px solid black;
  border-left: 2px solid black;
  overflow-x: hidden;
  overflow-y: scroll;
}

#notepad-text {
  height: 100%;
  overflow: hidden;
}
